export enum PackageState {
  pre_registered = 1,
  registered,
  downloaded_to_locker,
  in_work,
  on_hold,
  finalized,
  waiting_drawer_reassignment,
  drawer_reassignment_failed,
  package_missing,
  session_expired_door_closed,
  open_door,
  canceled,
  package_too_big,
  payment_failed,
}

export enum AWBType {
  standard = 1,
  'redirected',
  returned,
}

export enum Wrokflow {
  drawer_reservation = 1,
  package_drop_off_courier,
  package_pick_up_courier,
  package_drop_off_partner,
  package_drop_off_customer,
  package_pick_up_customer,
}

export interface PACKAGE_PAYMENTS {
  amount?: number;
  type?: number;
}

export interface Package {
  awb: string;
  awbType: number;
  clientPIN: string;
  courierCode: string;
  createdAt: string;
  customerId: string;
  drawerId: string;
  expirationDate: string;
  height: number;
  length: number;
  lockerCode: string;
  lockerId: string;
  packageId: string;
  packageSnapshots: [];
  packageState: number;
  payments: PACKAGE_PAYMENTS[];
  updatedAt: string;
  width: number;
  workflow: number;
}

export enum PACKAGE_HISTORY_TYPE {
  LOCKER = 'locker',
  GLOBAL = 'global',
}

export enum PACKAGE_PAYMENT_TYPE {
  TRANSPORTATION = 1,
  REINBURSEMENT = 2,
}

export interface Payment {
  amount: number;
  type: PACKAGE_PAYMENT_TYPE;
}
