import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { getUser } from '../../services/authService';
import { updateAccountSettings } from '../../services/requests';
import LoadingComponent from './LoadingComponent';
import ErrorDialog from './ErrorDialog';
import {
  getPortalLocale,
  setLocalStorageKey,
  setSessionStorageKey,
} from '../../utils';
import {
  CARRIER_LOCALE_STOARGE_KEY,
  UNAUTH_CARRIER_LOCALE_STOARGE_KEY,
} from '../../constants';

function LanguageSelector() {
  const { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    getPortalLocale()
  );

  const setLanguageUnauthenticatedUser = (language: string) => {
    setSelectedLanguage(language);
    setLocalStorageKey(UNAUTH_CARRIER_LOCALE_STOARGE_KEY, language);
    i18n.changeLanguage(language);
  };

  const setLanguageAuthenticatedUser = (language: string) => {
    setSessionStorageKey(CARRIER_LOCALE_STOARGE_KEY, language);
    setLanguageUnauthenticatedUser(language);
  };

  const languageMutation = useMutation(updateAccountSettings, {
    onSuccess(data) {
      const {
        data: { locale },
      } = data;
      setLanguageAuthenticatedUser(locale);
    },
  });

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [i18n.language]);

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const language = event.target.value;
    if (getUser()) {
      languageMutation.mutate({ locale: language });
    } else {
      setLanguageUnauthenticatedUser(language);
    }
  };

  return (
    <Box
      className='language-selector'
      sx={{ display: 'flex', alignItems: 'center', ml: 2, mb: 2 }}
    >
      {languageMutation.isLoading && <LoadingComponent />}
      {languageMutation.isError && (
        <ErrorDialog status message={t('something_went_wrong')} />
      )}
      <FormControl
        size='small'
        variant='outlined'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Select
          sx={{ minWidth: 147 }}
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <MenuItem value='en-US'>English</MenuItem>
          <MenuItem value='ro-RO'>Română</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default LanguageSelector;
