import { GridRowId } from '@mui/x-data-grid';

export interface County {
  countyId: string;
  name: string;
}
export interface Locality {
  localityId: string;
  name: string;
  aditionalInformation?: string;
  adM2Name?: string;
}

export interface Address {
  addressId?: string;
  county?: County;
  locality?: Locality;
  street?: string;
  buildingNumber?: string;
  floor?: string;
  postCode?: string;
  description?: string;
}
export interface AppVersion {
  version?: string;
  appVersionId?: string;
}

export interface Locker {
  name: string;
  lockerCode: string;
  lockerState: number | string;
  description: string;
  mac: string;
  appVersion?: AppVersion;
  targetVersion?: AppVersion;
  latitude: number | string;
  longitude: number | string;
  address?: Address;
  targetVersionId?: string;
  clientSecret?: string;
  posActive: string | boolean;
  lockerLocationType: number | string;
  lockerType: number | string;
  schedule?: string;
  clusterId?: string;
}

export interface DrawerType {
  drawerTypeId: string;
  size: number;
  width: number;
  height: number;
  length: number;
  label: string;
}

export interface DrawersToUpdate {
  drawerId: GridRowId;
  drawerState: number;
}

export interface Drawer {
  board: string;
  column: string;
  drawerId: string;
  drawerState: number;
  drawerType: DrawerType;
  isDeleted: boolean;
  isDoorOpen: boolean;
  row: string;
  id?: string;
  drawerCategory?: number;
  isCentralUnit?: boolean;
}
export interface DrawerToSave {
  row: string;
  column: string;
  board: string;
  drawerType: {
    drawerTypeId: string;
  };
  id: string;
  drawerState: number;
}
export interface DrawerDBReady {
  drawerId?: string;
  isDeleted?: boolean;
  row?: string;
  board?: string;
  column?: string;
}

export enum COLUMN_POSITION {
  Left = 'L',
  Right = 'R',
}

export enum DrawerState {
  active = 1,
  inactive,
  in_use,
  'maintenance_required',
  'cleaning_required',
  'maintenance_required_in_use',
  'cleaning_in_progress',
  'maintenance_in_progress',
}
export interface DrawerStructure {
  size: string;
  quantity: number;
}
export interface ColumnTemplate {
  type: string;
  structure: DrawerStructure[];
}

export enum DrawerTypeSize {
  small = 1,
  medium,
  large,
}

export enum OnlineStatus {
  online = 1,
  offline = 2,
}

export enum LockerState {
  'active' = 1,
  'inactive',
  'sync_issue',
  'cleaning_in_progress',
  'maintenance_in_progress',
}
export interface MainUnitInfo {
  mainUnitBoardId: string;
  hasMainUnitBoardId: boolean;
}

export enum DRAWER_OWNERSHIP {
  'own-drawer' = 1,
  'others-drawer',
  'free-drawer',
}
export interface IColumn {
  id: string;
  position: string;
  isModified?: boolean;
}

export const LOCKER_TYPES = {
  CONNECTED_LOCKER: 1,
  AUTHONOMOUS_LOCKER: 2,
};

export interface IDrawer {
  id: string;
  size: number;
  columnId?: string;
  position?: { column: string; row: string };
  drawerCategory?: number;
  isCentralUnit?: boolean;
}

export enum LOCKER_TYPE {
  'connected_locker' = 1,
  'autonomous_locker',
}

export interface DrawerPosition {
  column: string;
  row: string;
}

export type LockerColumnMap = Record<string, Drawer[]>;
