import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoadingLineComponent from './LoadingLineComponent';

const Layout = React.lazy(() => import('./Layout'));
const LockerList = React.lazy(() => import('../lockere/LockerList'));
const Locker = React.lazy(() => import('../lockere/Locker'));

const ClusterList = React.lazy(() => import('../clusters/ClusterList'));
const Cluster = React.lazy(() => import('../clusters/Cluster'));

const Events = React.lazy(() => import('../events/Events'));
const PackageHistory = React.lazy(
  () => import('../packageHistory/PackageHistory')
);
const PackageDetails = React.lazy(
  () => import('../packageHistory/PackageDetails')
);
const Configuration = React.lazy(
  () => import('../configuration/Configuration')
);

const Reports = React.lazy(() => import('../reports/Reports'));
const FleetLoadReport = React.lazy(() => import('../reports/FleetLoadReport'));
const LockerLoadReport = React.lazy(
  () => import('../reports/LockerLoadReport')
);

const FleetUptimeReport = React.lazy(
  () => import('../reports/FleetUptimeReport')
);

const DrawersRentalReport = React.lazy(
  () => import('../reports/DrawersRentalReport')
);

const MyAccount = React.lazy(() => import('../account/MyAccount'));

const PageNotFound = React.lazy(() => import('./PageNotFound'));

export default function PortalRoutes() {
  return (
    <div>
      <React.Suspense fallback={<LoadingLineComponent />}>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Navigate replace to='/lockers' />} />
            <Route path='lockers' element={<LockerList />} />
            <Route path='lockers/:lockerCode' element={<Locker />} />
            <Route path='clusters' element={<ClusterList />} />
            <Route path='clusters/:clusterId' element={<Cluster />} />
            <Route path='events' element={<Events />} />
            <Route path='packages-history' element={<PackageHistory />} />
            <Route path='packages-history/:id' element={<PackageDetails />} />
            <Route path='configuration' element={<Configuration />} />
            <Route path='reports' element={<Reports />} />
            <Route path='reports/fleet-load' element={<FleetLoadReport />} />
            <Route
              path='reports/fleet-load/:lockerCode'
              element={<LockerLoadReport />}
            />
            <Route path='reports/uptime' element={<FleetUptimeReport />} />
            <Route
              path='reports/drawers-rented'
              element={<DrawersRentalReport />}
            />
            <Route path='account-settings' element={<MyAccount />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </React.Suspense>
    </div>
  );
}
