import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ReactElement } from 'react';
import uniqid from 'uniqid';
import { AxiosError } from 'axios';
import { DateTime, Settings } from 'luxon';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import Fuse from 'fuse.js';
import {
  ABOVE_AVERAGE_FILL_COLOR,
  AVERAGE_FILL_COLOR,
  BELLOW_AVERAGE_FILL_COLOR,
  CARRIER_COUNTRY_CODE_STOARGE_KEY,
  CARRIER_LOCALE_STOARGE_KEY,
  CARRIER_TIMEZONE_STOARGE_KEY,
  COUNTRIES_CENTER_POINT_COORDINATES,
  COUNTRIES_COORDINATES_BOUNDS,
  COUNTRIES_MAPPING,
  DEFAULT_LOCALE,
  DEFAULT_TIMEZONE,
  errorMessages,
  EXTERNAL_DOMAINS,
  MIN_LENGTH_TEXT_FIELD,
  MIN_WIDTH_ACTIONS,
  RO_COUNTY_CODE,
  UNAUTH_CARRIER_LOCALE_STOARGE_KEY,
} from '../constants';
import {
  CustomJwtPayload,
  MIN_WIDTH_DATAGRID,
  QueryParams,
  SortDirection,
} from '../types/general.types';
import {
  Address,
  Drawer,
  DrawerPosition,
  DrawerStructure,
  DrawerType,
  IColumn,
  IDrawer,
  Locality,
  Locker,
  LOCKER_TYPES,
  LockerColumnMap,
} from '../types/locker.types';
import {
  PACKAGE_PAYMENT_TYPE,
  PackageState,
  Payment,
} from '../types/packages.types';
import i18n from '../config/i18n';
import { getUser } from '../services/authService';

export const generateDrawer = (size: string, columnId: string) => {
  return {
    size,
    id: uniqid(),
    columnId,
  };
};

export const validateEmpty = (field: string): boolean => {
  return !!field;
};

export const validateEmptyPOS = (field: string): boolean => {
  return field !== '';
};

export const validateAddress = (address: string): boolean => {
  const re = /^\w+( +\w+)*$/;
  return re.test(address);
};

export const validateAlphanumericOnly = (field: string): boolean => {
  const re = /^[0-9a-zA-Z ]+$/;
  return re.test(field);
};

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateNumber = (field: string): boolean => {
  const re = /^[0-9]+$/;
  return re.test(field);
};

export const validateTextField = (text?: string): boolean => {
  let isMinLength: boolean = false;
  if (text !== undefined) {
    if (text.length < MIN_LENGTH_TEXT_FIELD) {
      isMinLength = false;
    } else {
      isMinLength = true;
    }
  }

  return isMinLength;
};

export const validateDates = (startDate: Date | null, endDate: Date | null) => {
  let isDateValid: boolean = false;
  if (startDate !== null && endDate !== null) {
    if (startDate.getTime() <= endDate.getTime()) {
      isDateValid = true;
    }
  }
  return isDateValid;
};

export const validateLatitude = (coordinate: string) => {
  const re =
    /^(\+|-)?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?))$/;
  return re.test(coordinate);
};

export const validateLongitude = (coordinate: string) => {
  const re =
    /^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/;
  return re.test(coordinate);
};

export const validateIsString = (str: string) => {
  const re = /^[a-zA-Z]+$/;
  return re.test(str);
};

export const getActionsColumn = (
  renderCell: (params: GridRenderCellParams) => ReactElement,
  minWidth?: number
) => {
  return {
    field: 'actions',
    headerName: i18n.t('actions'),
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    minWidth: minWidth || MIN_WIDTH_ACTIONS,
    renderCell,
  };
};

export const getColumnDrawers = (
  drawersStructure: DrawerStructure[],
  columnId: string
) => {
  const drawers = [];
  for (let i = 0; i < drawersStructure.length; i += 1) {
    for (let j = 0; j < drawersStructure[i].quantity; j += 1) {
      drawers.push(generateDrawer(drawersStructure[i].size, columnId));
    }
  }
  return drawers;
};

export const getDateFormat = (date: Date, serverFormat = false) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return serverFormat
    ? `${[year, month, day].join('-')}`
    : `${[day, month, year].join('/')}`;
};

export const getCustomColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number
): GridColDef => {
  return {
    field: fieldName,
    headerName: i18n.t(headerName) as string,
    disableColumnMenu: true,
    flex: 1,
    minWidth,
  };
};

export const generateGridColumnsHeader = (
  availableHeaders: string[]
): GridColDef[] => {
  return availableHeaders.map((header) => {
    return {
      field: header,
      headerName: header,
      flex: 1,
    };
  });
};

export const generateGridColumnsHeaderRomanian = (
  availableHeaders: string[],
  availableDbNames: string[],
  minWidth: number,
  sortModel?: { field: string; sort: string }
): GridColDef[] => {
  return availableHeaders.map((header, idx) => {
    const headerName = i18n.t(header);
    return {
      field: availableDbNames[idx],
      headerName,
      flex: 1,
      disableColumnMenu: true,
      headerClassName:
        sortModel && sortModel.field === availableDbNames[idx]
          ? `data-grid-custom-${sortModel.sort}`
          : '',
      minWidth,
    };
  });
};

// Generates the query string used in endpoints
// to add search, sort, pagination.
export const getQueryString = (queryParams: QueryParams) => {
  const cleanQueryParams = Object.fromEntries(
    Object.entries(queryParams)
      .filter(([, val]) => val !== '')
      .map(([_, val]) => {
        return [_, val.toString()];
      })
  );
  return new URLSearchParams(cleanQueryParams).toString();
};

export const getDoorState = (isDrawerDoorOpen: boolean): string => {
  if (isDrawerDoorOpen) {
    return 'open';
  }
  return 'closed';
};

export const getLockerToSave = (rawLocker: Locker) => {
  const lockerToSave = { ...rawLocker };
  lockerToSave.lockerState = +rawLocker.lockerState;
  lockerToSave.longitude = +rawLocker.longitude;
  lockerToSave.latitude = +rawLocker.latitude;
  lockerToSave.lockerLocationType = +rawLocker.lockerLocationType;
  if (!lockerToSave.address?.addressId) {
    delete lockerToSave.address?.addressId;
  }
  if (!lockerToSave.targetVersionId) {
    delete lockerToSave.targetVersionId;
  }
  return lockerToSave;
};

export const downloadFile = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'application/octet-stream' })
  );
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('id', 'temporaryAnchor');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.getElementById('temporaryAnchor')?.remove();
};

export const downloadZip = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'application/zip' })
  );
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('id', 'temporaryAnchor');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.getElementById('temporaryAnchor')?.remove();
};

export enum LockerTabs {
  GeneralInformation = 'general_information',
  LockerSettings = 'locker_settings',
  DrawersStructure = 'drawer_structure',
  DrawersState = 'drawers_state',
  PackagesHistory = 'packages_history',
  DepositWithdrawalHistory = 'deposit_withdrawal_history',
}

export const getPackageState = (packageState: string): string => {
  if (packageState === PackageState[4]) {
    return 'drop_off';
  }
  return 'pick_up';
};

export const getErrorMessage = (error: AxiosError, defaultErrorMsg: string) => {
  const { response } = error;
  if (response && Array.isArray(response.data)) {
    return errorMessages[response.data[0]]
      ? i18n.t(errorMessages[response.data[0]])
      : defaultErrorMsg;
  }
  return defaultErrorMsg;
};

export const doesColumnIdentifierExists = (
  columnIdentifier: string,
  columnList: IColumn[]
) => {
  const columnIdentifierList = columnList.map((item: IColumn) => item.id);
  return columnIdentifierList.includes(columnIdentifier);
};

export const getLastWeeksDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
};

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand('copy', true, text);
}

export const isConnectedLocker = (lockerType: string) => {
  return +lockerType === LOCKER_TYPES.CONNECTED_LOCKER;
};

export const cutTextAtLength = (text: string, desiredLength: number) => {
  return text.length > desiredLength
    ? `${text.substring(0, desiredLength)} ...`
    : text;
};

export const getAddress = (address: Address): string => {
  let completeAdress = '';
  if (address) {
    const { buildingNumber, floor, street, postCode } = address;
    if (street) {
      completeAdress += `Str. ${street}`;
    }
    if (buildingNumber) {
      completeAdress += `, Nr. ${buildingNumber}`;
    }
    if (floor) {
      completeAdress += `, Etaj ${floor}`;
    }
    if (postCode) {
      completeAdress += `, Cod postal ${postCode}`;
    }
  }
  return completeAdress;
};

export const getYesterdayDate = () => {
  const now = new Date();
  now.setDate(now.getDate() - 1);
  return now;
};

export const getStartOfMonthyDate = () => {
  const now = new Date();
  now.setDate(1);
  return now;
};

export const getCountyColumn = () => {
  const column = getCustomColumn('judet', 'county', MIN_WIDTH_DATAGRID.LOCKERS);
  return {
    ...column,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row?.address?.county?.name;
    },
  };
};

export const getStartDateUTC = (date: Date) => {
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
};

export const getEndDateUTC = (date: Date) => {
  date.setUTCHours(23, 59, 59, 999);
  return date.toISOString();
};

export const getAddressColumn = () => {
  const column = getCustomColumn(
    'address',
    'address',
    MIN_WIDTH_DATAGRID.COMPLETE_ADDRESS
  );
  return {
    ...column,
    valueGetter: (params: GridValueGetterParams) => {
      return getAddress(params.row?.address);
    },
  };
};

export const getDonutChartFillColor = (value: number) => {
  if (value >= 90) {
    return ABOVE_AVERAGE_FILL_COLOR;
  }
  if (value > 50) {
    return AVERAGE_FILL_COLOR;
  }

  return BELLOW_AVERAGE_FILL_COLOR;
};

export const sortDrawersByRow = (drawerList: IDrawer[]) => {
  const drawersToSort = structuredClone(drawerList);
  return drawersToSort.sort((a: IDrawer, b: IDrawer) => {
    return (
      parseInt((a.position as DrawerPosition).row, 10) -
      parseInt((b.position as DrawerPosition).row, 10)
    );
  });
};

export const getPaymentAmount = (
  payments: Payment[],
  paymentType: PACKAGE_PAYMENT_TYPE
) => {
  const filteredPayments = payments.filter(
    (payment: Payment) => payment.type === paymentType
  );
  return filteredPayments[0] && filteredPayments[0].amount
    ? `${filteredPayments[0].amount} ron`
    : '';
};

// fuzzy search for arrays of objects
export const createFuseSearch = <T>(searchList: T[], keys: string[]) => {
  const options = {
    keys,
    threshold: 0.3,
  };
  return new Fuse(searchList, options);
};

export const filterMapLockerList = (lockers: Locker[]) => {
  const lockersNotInClusters: Locker[] = [];
  const lockersInClusters: Locker[] = [];
  lockers.forEach((el) => {
    if (Object.is(el.clusterId, null)) {
      lockersNotInClusters.push(el);
    } else {
      lockersInClusters.push(el);
    }
  });
  const lockersInClustersToShow = lockersInClusters.filter(
    (el, index, sourceArray) =>
      index === sourceArray.findIndex((item) => item.clusterId === el.clusterId)
  );

  return [...lockersNotInClusters, ...lockersInClustersToShow];
};

export const setSessionStorageKey = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const removeSessionStorageKey = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorageItem = (key: string) => {
  return sessionStorage.getItem(key);
};

export const decodeJWTToken = (token: string) => {
  return jwt_decode<CustomJwtPayload>(token);
};

export const getAuthUserData = (token: string) => {
  const decodedToken = decodeJWTToken(token);
  return {
    access_token: token,
    expires_at: decodedToken.exp,
    token_type: 'Bearer',
    profile: {
      user_type: decodedToken.client_user_type || '',
      username: decodedToken.client_preferred_username || '',
    },
  };
};

// password validation rule: min 8 characters, at least one lower case letter,
// at least one upper case letter, at least one number and
// at least one non alpha numeric character
export const validatePassword = (password: string) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-._!"`'#%&,:;<>=@{}~$()*/\\?[\]^|])[A-Za-z\d-._!"`'#%&,:;<>=@{}~$()*/\\?[\]^|]{8,}$/;
  return re.test(password);
};

export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const setDateTimeDefaults = () => {
  Settings.defaultLocale =
    (getSessionStorageItem(CARRIER_LOCALE_STOARGE_KEY) as string) ||
    DEFAULT_LOCALE;
  Settings.defaultZone =
    (getSessionStorageItem(CARRIER_TIMEZONE_STOARGE_KEY) as string) ||
    DEFAULT_TIMEZONE;
};

export const getDateTimeFormat = (date: string) => {
  setDateTimeDefaults();
  const dt = DateTime.fromISO(date);
  return dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
};

export const isoStringToDate = (date: string) => {
  setDateTimeDefaults();
  const dt = DateTime.fromISO(date);
  return dt.toLocaleString(DateTime.DATE_SHORT);
};

export const getUTCStartDateInMilis = (date: Date) => {
  setDateTimeDefaults();
  const dtStart = DateTime.fromJSDate(date);
  // set the start date at midnight 00:00:00
  const startDate = DateTime.local(
    dtStart.year,
    dtStart.month,
    dtStart.day,
    0,
    0,
    0
  );
  return startDate.toMillis();
};
export const getUTCEndDateInMilis = (date: Date) => {
  setDateTimeDefaults();
  const dtEnd = DateTime.fromJSDate(date);
  // set the end date at  23:59:59
  const endDate = DateTime.local(
    dtEnd.year,
    dtEnd.month,
    dtEnd.day,
    23,
    59,
    59
  );
  return endDate.toMillis();
};

export const getFullYear = (date: Date) => {
  setDateTimeDefaults();
  return DateTime.fromJSDate(date).toFormat('y');
};

export const getPaddedMonth = (date: Date) => {
  setDateTimeDefaults();
  return DateTime.fromJSDate(date).toFormat('LL');
};

export const getStartOfYearFromISOString = (isoString: string) => {
  setDateTimeDefaults();
  const date = DateTime.fromISO(isoString).toJSDate();
  const startOfYear = DateTime.fromJSDate(date).startOf('year').toJSDate();
  return startOfYear;
};

// endDate should be greater or equal with startDate
export const isValidActivityDate = (startDate: Date, endDate: Date) => {
  return getUTCEndDateInMilis(endDate) >= getUTCStartDateInMilis(startDate);
};

export const convertDateStringToDateShort = (dateString: string) => {
  const parsedDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd');

  return parsedDate.toLocaleString(DateTime.DATE_SHORT);
};

export const getCSVReportName = (
  baseName: string,
  startDate: string,
  endDate: string
) => {
  return `${baseName}-${convertDateStringToDateShort(
    startDate
  )}-${convertDateStringToDateShort(endDate)}.csv`;
};

export const getLocale = () => {
  return getSessionStorageItem(CARRIER_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE;
};

export const getTimezone = () => {
  return (
    getSessionStorageItem(CARRIER_TIMEZONE_STOARGE_KEY) || DEFAULT_TIMEZONE
  );
};

export const getCountryCentralPointCoordinates = () => {
  const countryCode = getSessionStorageItem(
    CARRIER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_CENTER_POINT_COORDINATES[countryCode!];
};

export const getCountryCoordinatesBounds = () => {
  const countryCode = getSessionStorageItem(
    CARRIER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_COORDINATES_BOUNDS[countryCode!];
};

export const getCountryName = () => {
  const countryCode = getSessionStorageItem(
    CARRIER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_MAPPING[countryCode!];
};

export const getLocalityLabel = (locality: Locality) => {
  const countryCode = getSessionStorageItem(
    CARRIER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  if (countryCode === RO_COUNTY_CODE) {
    return locality.name;
  }
  return locality.adM2Name
    ? `${locality.name}, ${locality.adM2Name}`
    : locality.name;
};

export const isExternalDomain = (urlString: string) => {
  try {
    const url = new URL(urlString);
    const domain = url.hostname;
    return EXTERNAL_DOMAINS.includes(domain);
  } catch (error) {
    return false;
  }
};

export const setLocalStorageKey = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const getPortalLocale = () => {
  if (getUser()) {
    return getSessionStorageItem(CARRIER_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE;
  }
  return (
    getLocalStorageItem(UNAUTH_CARRIER_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE
  );
};

// dateString should be in the 2023-10-13(yyyy-MM-dd) format
export const convertToUTCAtMidnight = (dateString: string) => {
  const inputDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd', {
    zone: 'utc',
  });

  const utcDate = inputDate
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .setZone('UTC');

  return utcDate.toISO();
};
// dateString should be in the 2023-10-13(yyyy-MM-dd) format
export const convertToUTCEndOfDay = (dateString: string) => {
  const inputDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd', {
    zone: 'utc',
  });

  const utcDate = inputDate
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .setZone('UTC');

  return utcDate.toISO();
};

// the startDate and endDate should be in the 2023-10-13(yyyy-MM-dd) format
export const getStartOfTheDayEndOfTheDayUTC = (
  startDate: string,
  endDate: string
) => {
  return {
    startDate: convertToUTCAtMidnight(startDate),
    endDate: convertToUTCEndOfDay(endDate),
  };
};

export const jsDateToYYYYMMDDFormat = (date: Date) => {
  return date.toISOString().slice(0, 10);
};

export const getDrawerDimensions = (drawer: DrawerType) => {
  const { width, height, length, label } = drawer;
  return `${label} (${i18n.t('width_short')} ${width}cm x ${i18n.t(
    'height_short'
  )} ${height}cm x ${i18n.t('length_short')} ${length}cm)`;
};

export const getDrawerTypeBySize = (
  drawerTypeList: DrawerType[],
  size: number
) => {
  return drawerTypeList.find((drawerType) => drawerType.size === size);
};

export const sortDrawerTypesBySize = (drawerTypeList: DrawerType[]) => {
  return drawerTypeList.sort((a: DrawerType, b: DrawerType) => a.size - b.size);
};

export const getLockerColumns = (drawers: Drawer[]): LockerColumnMap => {
  return drawers.reduce((result, drawer) => {
    const { column } = drawer;

    if (!result[column]) {
      // eslint-disable-next-line no-param-reassign
      result[column] = [];
    }

    result[column].push(drawer);

    return result;
  }, {} as LockerColumnMap);
};

export const sortArrayOfObjByProperty = (
  array: any[],
  property: string,
  direction: SortDirection = 'asc'
) => {
  return [...array].sort((a, b) => {
    if (a[property] < b[property]) {
      return direction === 'asc' ? -1 : 1;
    }
    if (a[property] > b[property]) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const sortDrawerTypesByVolume = (drawerTypeList: DrawerType[]) => {
  const drawersWithVolume = drawerTypeList.map((drawerType) => ({
    ...drawerType,
    volume: drawerType.width * drawerType.length * drawerType.height,
  }));

  const sortedDrawers = drawersWithVolume.sort((a, b) => a.volume - b.volume);

  return sortedDrawers;
};

export const formatNumberToDecimalOrInteger = (num: number) => {
  if (Number.isInteger(num)) {
    return num;
  }
  return num.toFixed(2);
};

// 90 days ago starting yesterday
export const getDailyReportsMinDate = () => {
  setDateTimeDefaults();
  const yesterday = DateTime.utc().minus({ days: 1 });
  const ninetyDaysAgo = yesterday.minus({ days: 90 });
  return ninetyDaysAgo.toJSDate();
};
