import { JwtPayload } from 'jwt-decode';

export interface QueryParams {
  [key: string]: string | number | boolean;
}

export interface RoutesType {
  [key: string]: string;
}

export interface IPagination {
  totalCount: number;
}

export interface PaginationInfo {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export enum MIN_WIDTH_DATAGRID {
  EVENTS = 170,
  DRAWER_STATE = 170,
  LOCKERS = 200,
  PACKAGES_HISTORY = 150,
  PACKAGE_SNAPSHOT = 170,
  APP_VERSIONS = 200,
  COMPLETE_ADDRESS = 600,
  FLEET_LOAD = 170,
  LOCKER_LOAD = 100,
  CLUSTERS = 170,
}

export enum EVENT_TYPE {
  DRAWER = 'drawerId',
  PACKAGE = 'packageId',
}

export enum MomentType {
  Incident = 1,
  Finalizat = 2,
}

export enum FILTER_DATE_ACTION {
  Incidents = 'see_incidents',
}

export type DateType = Date | null | undefined;

export enum Direction {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum ReportType {
  FLEET_LOAD = 1,
  FLEET_UPTIME = 2,
  DRAWERS_RENTAL = 3,
}

export enum DownloadReportType {
  FLEET_LOAD = 1,
  FLEET_UPTIME = 2,
  PACKAGE_WORKFLOW_TIME = 3,
  CLIENT_BILLING_DATA = 4,
}

export interface CustomerBillingData {
  drawerSize: number;
  ownedDays: number;
  ownedDrawers: number;
  sharedDays: number;
  sharedDrawers: number;
}

export interface PostLoginParams {
  username: string;
  password: string;
}

export interface SuccessLoginData {
  accessToken: string;
  userSettings: {
    locale: string;
    timezone: string;
    countryCode: string;
  };
}

export interface ForgotPasswordParams {
  email: string;
  setPasswordRoute: string;
  cultureName: string;
}

export interface CustomJwtPayload extends JwtPayload {
  client_user_type: string;
  client_preferred_username: string;
}

export interface ChangePasswordParams {
  userId: string;
  password: string;
  token: string;
}

export interface TimeZone {
  key: string;
  value: string;
}

export type KeyValueParams = {
  [key: string]: any;
};

export type SortDirection = 'asc' | 'desc';
