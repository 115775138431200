import { Box, Hidden, Typography } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';

export default function UnauthenticatedTopBar() {
  const navigate = useNavigate();

  return (
    <Box
      className='unauthenticated-header'
      sx={{
        height: '56px',
        backgroundColor: '#258ec5',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      <Hidden smDown>
        <Box
          sx={{
            width: '180px',
            backgroundColor: 'white',
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: '5px',
            paddingTop: '5px',
            height: '55px',

            zIndex: 100,
          }}
        >
          <Link to='/'>
            <Box
              component='img'
              sx={{
                width: '163px',
                height: '45.7px',
              }}
              alt='logo Arka'
              src={`${process.env.PUBLIC_URL}/logo.webp`}
            />
          </Link>
        </Box>
      </Hidden>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{ color: 'white', cursor: 'default', pl: 3 }}
          onClick={() => navigate('/')}
        >
          Arka Carrier Portal
        </Typography>
        <LanguageSelector />
      </Box>
    </Box>
  );
}
