import { LatLngExpression } from 'leaflet';
import { RoutesType } from '../types/general.types';

export const SIDEBAR_WIDTH = 180;
export const MIN_WIDTH_ACTIONS = 130;
export const ITEMS_PER_PAGE = [10, 20, 50];
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const PAGINATION_INITIAL_PAGE_INDEX = 0;

export const ROUTES: RoutesType = {
  lockers: '/lockers',
  clusters: '/clusters',
  events: '/events',
  packages_history: '/packages-history',
  configuration: '/configuration',
  reports: '/reports',
  account_settings: '/account-settings',
};

export const LOCKER_KEYS = [
  'lockerCode',
  'lockerState',
  'posActive',
  'createdAt',
  'updatedAt',
  'mac',
  'appVersion',
  'name',
  'onlineStatus',
  'lockerType',
];

export const LOCKER_AVAILABLE_HEADERS = [
  'locker_code',
  'status',
  'pos_status',
  'created_at',
  'updated_at',
  'mac_address',
  'app_version',
  'name',
  'conectivity',
  'locker_type',
];

export const DRAWER_INCIDENT_KEYS = [
  'drawerState',
  'column',
  'row',
  'lockerCode',
  'drawerId',
  'createdAt',
  'updatedAt',
];

export const DRAWER_INCIDENT_HEADERS = [
  'status',
  'column',
  'row',
  'locker_code',
  'drawer_id',
  'created_at',
  'updated_at',
];

export const PACKAGE_INCIDENT_KEYS = [
  'packageId',
  'packageState',
  'createdAt',
  'updatedAt',
  'expirationDate',
  'workflow',
  'awb',
  'lockerId',
  'drawerId',
];

export const PACKAGE_INCIDENT_HEADERS = [
  'package_id',
  'status',
  'created_at',
  'updated_at',
  'expiration_date',
  'workflow',
  'awb',
  'locker_id',
  'drawer_id',
];

export const LOCKER_STATES: { [key: string]: string } = {
  1: 'active',
  2: 'inactive',
  3: 'sync_issue',
  4: 'cleaning_in_progress',
  5: 'maintenance_in_progress',
};

export const POS_STATES: { [key: string]: string } = {
  true: 'active',
  false: 'inactive',
};

export const LOCKER_LOCATION_TYPE: { [key: string]: string } = {
  1: 'inside',
  2: 'outside',
};

export const MIN_LENGTH_TEXT_FIELD = 3;
export const DATE_FORMAT = 'dd/MM/yyyy';

export const LOCKER_DRAWERS_STATE_HEADERS = [
  'drawer_state',
  'row',
  'column',
  'column_identifier',
  'updated_at',
  'door_status',
  'drawer_type',
  'created_at',
];

export const LOCKER_DRAWERS_STATE_KEYS = [
  'drawerState',
  'row',
  'column',
  'board',
  'updatedAt',
  'isDoorOpen',
  'drawerType',
  'createdAt',
];

export const PACKAGE_AVAILABLE_HEADERS = [
  'locker_code',
  'package_state',
  'awb',
  'identifier',
  'shipping_price',
  'package_price',
  'awb_type',
  'workflow',
  'created_at',
  'updated_at',
  'expiration_date',
  'customer_pin',
  'courier_code',
  'drawer_id',
];

export const PACKAGE_KEYS = [
  'lockerCode',
  'packageState',
  'awb',
  'displayIdentifier',
  'transportationAmount',
  'packageAmount',
  'awbType',
  'workflow',
  'createdAt',
  'updatedAt',
  'expirationDate',
  'clientPIN',
  'courierCode',
  'drawerId',
];

export const PACKAGE_SNAPSHOTS_AVAILABLE_HEADERS = [
  'awb_type',
  'package_id',
  'package_state',
  'shipping_price',
  'package_price',
  'drawer_type',
  'workflow',
  'locker_id',
  'locker_status',
  'expiration_date',
  'drawer_id',
  'drawer_state',
  'door_status',
  'courier_code',
  'customer_pin',
  'created_at',
  'updated_at',
];

export const PACKAGE_SNAPSHOTS_KEYS = [
  'awbType',
  'packageId',
  'packageState',
  'transportationAmount',
  'packageAmount',
  'assignedSize',
  'workflow',
  'lockerId',
  'lockerState',
  'expirationDate',
  'drawerId',
  'drawerState',
  'drawerIsDoorOpen',
  'courierCode',
  'clientPIN',
  'snapshotCreationDate',
  'packageUpdatedAt',
];

export const errorMessages: Record<string, string> = {
  ADDRESS_LOCALITY_NOT_FOUND: 'ADDRESS_LOCALITY_NOT_FOUND',
  ADDRESS_COUNTY_NOT_FOUND: 'ADDRESS_COUNTY_NOT_FOUND',
  ADDRESS_COUNTY_MANDATORY: 'ADDRESS_COUNTY_MANDATORY',
  USER_UNAUTHORIZED: 'USER_UNAUTHORIZED',
  LOCKER_NOT_FOUND: 'LOCKER_NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  PACKAGE_BY_ID_NOT_FOUND: 'PACKAGE_BY_ID_NOT_FOUND',
  PACKAGE_BY_AWB_NOT_FOUND: 'PACKAGE_BY_AWB_NOT_FOUND',
  NO_PACKAGES_FOUND: 'NO_PACKAGES_FOUND',
  INVALID_DATETIME_OF_VALIDTO: 'INVALID_DATETIME_OF_VALIDTO',
  CAN_NOT_SET_DRAWER_STATUS_INUSE: 'CAN_NOT_SET_DRAWER_STATUS_INUSE',
  INVALID_DRAWER_STATUS_INUSE: 'INVALID_DRAWER_STATUS_INUSE',
  INVALID_CREATE_NEW_DELETED_DRAWER: 'INVALID_CREATE_NEW_DELETED_DRAWER',
  DRAWER_POSITION_NOT_UNIQUE: 'DRAWER_POSITION_NOT_UNIQUE',
  DRAWER_CAN_NOT_BE_DELETED: 'DRAWER_CAN_NOT_BE_DELETED',
  DRAWER_NOT_FOUND_ON_LOCKER: 'DRAWER_NOT_FOUND_ON_LOCKER',
  DRAWER_NOT_FOUND: 'DRAWER_NOT_FOUND',
  INVALID_USER_EMAIL: 'INVALID_USER_EMAIL',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
};

export const drawerOwnershipDataGridLegend = [
  { label: 'own_drawer', bgColorClass: 'own-drawer' },
  {
    label: 'drawer_owned_another_customer',
    bgColorClass: 'others-drawer',
  },
  { label: 'drawer_available_for_rent', bgColorClass: 'free-drawer' },
];

export const CONNECTED_LOCKER = 'Locker conectat';
export const ROW_DISABLED_CLASS = 'row-disabled';

export const lockerListDataGridLegend = [
  { label: 'connected_locker', bgColorClass: 'locker-type-connected' },
  { label: 'autonomous_locker', bgColorClass: 'locker-type-autonomous' },
  { label: 'locker_included_in_cluster', bgColorClass: 'cluster-locker' },
  { label: 'deleted_locker', bgColorClass: 'locker-deleted' },
];

export const FLEET_LOAD_REPORT_KEYS = [
  'lockerCode',
  'loadingPercentage',
  'lockerType',
  'lockerLocationType',
];

export const FLEET_LOAD_REPORT_HEADERS = [
  'locker_code',
  'load_upper',
  'locker_type',
  'location',
];

export const LOCKER_LOAD_REPORT_HEADERS = [
  'row',
  'column',
  'drawer_type',
  'number_of_loads',
];

export const LOCKER_LOAD_REPORT_KEYS = [
  'row',
  'column',
  'drawerSize',
  'packageCount',
];

export const FLEET_UPTIME_REPORT_KEYS = [
  'lockerCode',
  'onlinePercentage',
  'lockerType',
  'lockerLocationType',
];

export const FLEET_UPTIME_REPORT_HEADERS = [
  'locker_code',
  'uptime_title',
  'locker_type',
  'location',
];

export const ABOVE_AVERAGE_THRESHOLD = 90;
export const AVERAGE_THRESHOLD = 50;
export const BELLOW_AVERAGE_THRESHOLD = 50;

export const ABOVE_AVERAGE_FILL_COLOR = '#4caf50';
export const AVERAGE_FILL_COLOR = '#ed6c02';
export const BELLOW_AVERAGE_FILL_COLOR = '#d32f2f';

export const INITAL_MAP_ZOOM_LEVEL = 6;
export const MAP_MAX_ZOOM_LEVEL = 18;
export const INITAL_CENTER_OF_THE_MAP_COORDINATES: LatLngExpression = [46, 25]; // center of Romania
export const MAP_CLUSTER_RADIUS = 150;
export const ROMANIA_COORDINATES_BOUNDS = [
  43.618682, 48.2654738, 20.2619955, 30.0454257,
];

export const CARRIER_LOCALE_STOARGE_KEY = 'CARRIER_LOCALE';
export const CARRIER_ACCESS_TOKEN_KEY = 'CARRIER_ACCESS_TOKEN';
export const CARRIER_TIMEZONE_STOARGE_KEY = 'CARRIER_TIMEZONE_STOARGE_KEY';
export const SET_PASSWORD_ROUTE = 'create-password';
export const FORGOT_PASSWORD_ROUTE = 'reset-password';

export const MAP_LOCALE_TO_DATE_FNS_LOCALE: Record<string, string> = {
  'ro-RO': 'ro',
  'en-US': 'en-US',
};

export const CLUSTER_LIST_KEYS = ['name', 'createdAt', 'updatedAt'];
export const CLUSTER_LIST_HEADERS = ['name', 'created_at', 'updated_at'];

export const AUTONOMOUS_LOCKER_KEYS = [
  'lockerCode',
  'lockerState',
  'name',
  'onlineStatus',
  'lockerType',
];

export const AUTONOMOUS_LOCKER_HEADERS = [
  'locker_code',
  'status',
  'name',
  'conectivity',
  'locker_type',
];

export const TIMEZONES: Record<string, string> = {
  'Africa/Abidjan': '(UTC+00:00) Africa/Abidjan',
  'Africa/Algiers': '(UTC+01:00) Africa/Algiers',
  'Africa/Bissau': '(UTC+00:00) Africa/Bissau',
  'Africa/Cairo': '(UTC+02:00, +03:00) Africa/Cairo',
  'Africa/Casablanca': '(UTC+00:00, +01:00) Africa/Casablanca',
  'Africa/Ceuta': '(UTC+01:00, +02:00) Africa/Ceuta',
  'Africa/El_Aaiun': '(UTC+00:00, +01:00) Africa/El Aaiun',
  'Africa/Johannesburg': '(UTC+02:00) Africa/Johannesburg',
  'Africa/Juba': '(UTC+02:00, +03:00) Africa/Juba',
  'Africa/Khartoum': '(UTC+02:00, +03:00) Africa/Khartoum',
  'Africa/Lagos': '(UTC+01:00) Africa/Lagos',
  'Africa/Maputo': '(UTC+02:00) Africa/Maputo',
  'Africa/Monrovia': '(UTC+00:00) Africa/Monrovia',
  'Africa/Nairobi': '(UTC+03:00) Africa/Nairobi',
  'Africa/Ndjamena': '(UTC+01:00) Africa/Ndjamena',
  'Africa/Sao_Tome': '(UTC+00:00, +01:00) Africa/Sao Tome',
  'Africa/Tripoli': '(UTC+01:00, +02:00) Africa/Tripoli',
  'Africa/Tunis': '(UTC+01:00, +02:00) Africa/Tunis',
  'Africa/Windhoek': '(UTC+01:00, +02:00) Africa/Windhoek',
  'America/Adak': '(UTC-10:00, -09:00) America/Adak',
  'America/Anchorage': '(UTC-09:00, -08:00) America/Anchorage',
  'America/Araguaina': '(UTC-03:00, -02:00) America/Araguaina',
  'America/Argentina/Buenos_Aires':
    '(UTC-03:00, -02:00) America/Argentina/Buenos Aires',
  'America/Argentina/Catamarca':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Catamarca',
  'America/Argentina/Cordoba': '(UTC-03:00, -02:00) America/Argentina/Cordoba',
  'America/Argentina/Jujuy': '(UTC-03:00, -02:00) America/Argentina/Jujuy',
  'America/Argentina/La_Rioja':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/La Rioja',
  'America/Argentina/Mendoza':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Rio Gallegos',
  'America/Argentina/Salta': '(UTC-03:00, -02:00) America/Argentina/Salta',
  'America/Argentina/San_Juan':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/San Juan',
  'America/Argentina/San_Luis':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/San Luis',
  'America/Argentina/Tucuman':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Tucuman',
  'America/Argentina/Ushuaia':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Ushuaia',
  'America/Asuncion': '(UTC-04:00, -03:00) America/Asuncion',
  'America/Bahia': '(UTC-03:00, -02:00) America/Bahia',
  'America/Bahia Banderas':
    '(UTC-07:00, -06:00, -05:00) America/Bahia Banderas',
  'America/Barbados': '(UTC-04:00) America/Barbados',
  'America/Belem': '(UTC-03:00) America/Belem',
  'America/Belize': '(UTC-06:00) America/Belize',
  'America/Boa_Vista': '(UTC-04:00, -03:00) America/Boa Vista',
  'America/Bogota': '(UTC-05:00) America/Bogota',
  'America/Boise': '(UTC-07:00, -06:00) America/Boise',
  'America/Cambridge_Bay': '(UTC-07:00, -06:00, -05:00) America/Cambridge Bay',
  'America/Campo_Grande': '(UTC-04:00, -03:00) America/Campo Grande',
  'America/Cancun': '(UTC-06:00, -05:00) America/Cancun',
  'America/Caracas': '(UTC-04:30, -04:00) America/Caracas',
  'America/Cayenne': '(UTC-03:00) America/Cayenne',
  'America/Chicago': '(UTC-06:00, -05:00) America/Chicago',
  'America/Chihuahua': '(UTC-07:00, -06:00) America/Chihuahua',
  'America/Ciudad_Juarez': '(UTC-07:00, -06:00) America/Ciudad Juarez',
  'America/Costa_Rica': '(UTC-06:00) America/Costa Rica',
  'America/Cuiaba': '(UTC-04:00, -03:00) America/Cuiaba',
  'America/Danmarkshavn': '(UTC+00:00) America/Danmarkshavn',
  'America/Dawson': '(UTC-08:00, -07:00) America/Dawson',
  'America/Dawson_Creek': '(UTC-07:00) America/Dawson Creek',
  'America/Denver': '(UTC-07:00, -06:00) America/Denver',
  'America/Detroit': '(UTC-05:00, -04:00) America/Detroit',
  'America/Edmonton': '(UTC-07:00, -06:00) America/Edmonton',
  'America/Eirunepe': '(UTC-05:00, -04:00) America/Eirunepe',
  'America/El_Salvador': '(UTC-06:00) America/El Salvador',
  'America/Fort_Nelson': '(UTC-08:00, -07:00) America/Fort Nelson',
  'America/Fortaleza': '(UTC-03:00, -02:00) America/Fortaleza',
  'America/Glace_Bay': '(UTC-04:00, -03:00) America/Glace Bay',
  'America/Goose_Bay': '(UTC-04:00, -03:00) America/Goose Bay',
  'America/Grand_Turk': '(UTC-05:00, -04:00) America/Grand Turk',
  'America/Guatemala': '(UTC-06:00, -05:00) America/Guatemala',
  'America/Guayaquil': '(UTC-05:00) America/Guayaquil',
  'America/Guyana': '(UTC-04:00) America/Guyana',
  'America/Halifax': '(UTC-04:00, -03:00) America/Halifax',
  'America/Havana': '(UTC-05:00, -04:00) America/Havana',
  'America/Hermosillo': '(UTC-07:00) America/Hermosillo',
  'America/Indiana/Indianapolis':
    '(UTC-05:00, -04:00) America/Indiana/Indianapolis',
  'America/Indiana/Knox': '(UTC-06:00, -05:00) America/Indiana/Knox',
  'America/Indiana/Marengo': '(UTC-05:00, -04:00) America/Indiana/Marengo',
  'America/Indiana/Petersburg':
    '(UTC-06:00, -05:00, -04:00) America/Indiana/Petersburg',
  'America/Indiana/Tell_City': '(UTC-06:00, -05:00) America/Indiana/Tell City',
  'America/Indiana/Vevay': '(UTC-05:00, -04:00) America/Indiana/Vevay',
  'America/Indiana/Vincennes':
    '(UTC-06:00, -05:00, -04:00) America/Indiana/Vincennes',
  'America/Indiana/Winamac':
    '(UTC-06:00, -05:00, -04:00) America/Indiana/Winamac',
  'America/Inuvik': '(UTC-07:00, -06:00) America/Inuvik',
  'America/Iqaluit': '(UTC-06:00, -05:00, -04:00) America/Iqaluit',
  'America/Jamaica': '(UTC-05:00) America/Jamaica',
  'America/Juneau': '(UTC-09:00, -08:00) America/Juneau',
  'America/Kentucky/Louisville':
    '(UTC-05:00, -04:00) America/Kentucky/Louisville',
  'America/Kentucky/Monticello':
    '(UTC-06:00, -05:00, -04:00) America/Kentucky/Monticello',
  'America/La_Paz': '(UTC-04:00) America/La Paz',
  'America/Lima': '(UTC-05:00) America/Lima',
  'America/Los_Angeles': '(UTC-08:00, -07:00) America/Los Angeles',
  'America/Maceio': '(UTC-03:00, -02:00) America/Maceio',
  'America/Managua': '(UTC-06:00, -05:00) America/Managua',
  'America/Manaus': '(UTC-04:00) America/Manaus',
  'America/Martinique': '(UTC-04:00) America/Martinique',
  'America/Matamoros': '(UTC-06:00, -05:00) America/Matamoros',
  'America/Mazatlan': '(UTC-07:00, -06:00) America/Mazatlan',
  'America/Menominee': '(UTC-06:00, -05:00) America/Menominee',
  'America/Merida': '(UTC-06:00, -05:00) America/Merida',
  'America/Metlakatla': '(UTC-09:00, -08:00) America/Metlakatla',
  'America/Mexico_City': '(UTC-06:00, -05:00) America/Mexico City',
  'America/Miquelon': '(UTC-03:00, -02:00) America/Miquelon',
  'America/Moncton': '(UTC-04:00, -03:00) America/Moncton',
  'America/Monterrey': '(UTC-06:00, -05:00) America/Monterrey',
  'America/Montevideo': '(UTC-03:00, -02:00) America/Montevideo',
  'America/New_York': '(UTC-05:00, -04:00) America/New York',
  'America/Nome': '(UTC-09:00, -08:00) America/Nome',
  'America/Noronha': '(UTC-02:00, -01:00) America/Noronha',
  'America/North_Dakota/Beulah':
    '(UTC-07:00, -06:00, -05:00) America/North_Dakota/Beulah',
  'America/North_Dakota/Center':
    '(UTC-06:00, -05:00) America/North_Dakota/Center',
  'America/North_Dakota/New_Salem':
    '(UTC-07:00, -06:00, -05:00) America/North_Dakota/New Salem',
  'America/Nuuk': '(UTC-03:00, -02:00, -01:00) America/Nuuk',
  'America/Ojinaga': '(UTC-07:00, -06:00, -05:00) America/Ojinaga',
  'America/Panama': '(UTC-05:00) America/Panama',
  'America/Paramaribo': '(UTC-03:00) America/Paramaribo',
  'America/Phoenix': '(UTC-07:00) America/Phoenix',
  'America/Port-au-Prince': '(UTC-05:00, -04:00) America/Port-au-Prince',
  'America/Porto_Velho': '(UTC-04:00) America/Porto Velho',
  'America/Puerto_Rico': '(UTC-04:00) America/Puerto Rico',
  'America/Punta_Arenas': '(UTC-04:00, -03:00) America/Punta Arenas',
  'America/Rankin_Inlet': '(UTC-06:00, -05:00) America/Rankin Inlet',
  'America/Recife': '(UTC-03:00, -02:00) America/Recife',
  'America/Regina': '(UTC-06:00) America/Regina',
  'America/Resolute': '(UTC-06:00, -05:00) America/Resolute',
  'America/Rio_Branco': '(UTC-05:00, -04:00) America/Rio Branco',
  'America/Santarem': '(UTC-04:00, -03:00) America/Santarem',
  'America/Santiago': '(UTC-04:00, -03:00) America/Santiago',
  'America/Santo_Domingo': '(UTC-05:00, -04:00) America/Santo Domingo',
  'America/Sao_Paulo': '(UTC-03:00, -02:00) America/Sao Paulo',
  'America/Scoresbysund': '(UTC-01:00, 00:00) America/Scoresbysund',
  'America/Sitka': '(UTC-09:00, -08:00) America/Sitka',
  'America/St_Johns': '(UTC-03:30, -02:30) America/St Johns',
  'America/Swift_Current': '(UTC-06:00) America/Swift Current',
  'America/Tegucigalpa': '(UTC-06:00, -05:00) America/Tegucigalpa',
  'America/Thule': '(UTC-04:00, -03:00) America/Thule',
  'America/Tijuana': '(UTC-08:00, -07:00) America/Tijuana',
  'America/Toronto': '(UTC-05:00, -04:00) America/Toronto',
  'America/Vancouver': '(UTC-08:00, -07:00) America/Vancouver',
  'America/Whitehorse': '(UTC-08:00, -07:00) America/Whitehorse',
  'America/Winnipeg': '(UTC-06:00, -05:00) America/Winnipeg',
  'America/Yakutat': '(UTC-09:00, -08:00) America/Yakutat',
  'Antarctica/Casey': '(UTC+08:00, +11:00) Antarctica/Casey',
  'Antarctica/Davis': '(UTC+05:00, +07:00) Antarctica/Davis',
  'Antarctica/Macquarie': '(UTC+10:00, +11:00) Antarctica/Macquarie',
  'Antarctica/Mawson': '(UTC+05:00, +06:00) Antarctica/Macquarie',
  'Antarctica/Palmer': '(UTC-04:00, -03:00) Antarctica/Palmer',
  'Antarctica/Rothera': '(UTC-03:00) Antarctica/Rothera',
  'Antarctica/Troll': '(UTC+00:00, +02:00) Antarctica/Troll',
  'Asia/Almaty': '(UTC+06:00, +07:00) Asia/Almaty',
  'Asia/Amman': '(UTC+02:00, +03:00) Asia/Amman',
  'Asia/Anadyr': '(UTC+11:00, +12:00, +13:00) Asia/Anadyr',
  'Asia/Aqtau': '(UTC+04:00, +05:00) Asia/Aqtau',
  'Asia/Aqtobe': '(UTC+05:00, +06:00) Asia/Aqtobe',
  'Asia/Ashgabat': '(UTC+05:00) Asia/Ashgabat',
  'Asia/Atyrau': '(UTC+04:00, +05:00) Asia/Atyrau',
  'Asia/Baghdad': '(UTC+03:00, +04:00) Asia/Baghdad',
  'Asia/Baku': '(UTC+04:00, +05:00) Asia/Baku',
  'Asia/Bangkok': '(UTC+07:00) Asia/Bangkok',
  'Asia/Barnaul': '(UTC+06:00, +07:00) Asia/Barnaul',
  'Asia/Beirut': '(UTC+02:00, +03:00) Asia/Beirut',
  'Asia/Bishkek': '(UTC+05:00, +06:00) Asia/Bishkek',
  'Asia/Chita': '(UTC+08:00, +09:00, +10:00) Asia/Chita',
  'Asia/Choibalsan': '(UTC+08:00, +09:00, +10:00) Asia/Choibalsan',
  'Asia/Colombo': '(UTC+05:30, +06:00) Asia/Colombo',
  'Asia/Damascus': '(UTC+02:00, +03:00) Asia/Damascus',
  'Asia/Dhaka': '(UTC+06:00, +07:00) Asia/Dhaka',
  'Asia/Dili': '(UTC+08:00, +09:00) Asia/Dili',
  'Asia/Dubai': '(UTC+04:00) Asia/Dubai',
  'Asia/Dushanbe': '(UTC+05:00) Asia/Dushanbe',
  'Asia/Famagusta': '(UTC+02:00, +03:00) Asia/Famagusta',
  'Asia/Gaza': '(UTC+02:00, +03:00) Asia/Gaza',
  'Asia/Hebron': '(UTC+02:00, +03:00) Asia/Hebron',
  'Asia/Ho_Chi_Minh': '(UTC+07:00) Asia/Ho Chi Minh',
  'Asia/Hong_Kong': '(UTC+08:00) Asia/Hong Kong',
  'Asia/Hovd': '(UTC+07:00, +08:00) Asia/Hovd',
  'Asia/Jakarta': '(UTC+07:00) Asia/Jakarta',
  'Asia/Jayapura': '(UTC+09:00) Asia/Jayapura',
  'Asia/Jerusalem': '(UTC+02:00, +03:00) Asia/Jerusalem',
  'Asia/Karachi': '(UTC+05:00, +06:00) Asia/Karachi',
  'Asia/Kathmandu': '(UTC+05:45) Asia/Kathmandu',
  'Asia/Kolkata': '(UTC+05:30) Asia/Kolkata',
  'Asia/Kuching': '(UTC+08:00) Asia/Kuching',
  'Asia/Macau': '(UTC+08:00) Asia/Macau',
  'Asia/Makassar': '(UTC+08:00) Asia/Makassar',
  'Asia/Manila': '(UTC+08:00) Asia/Manila',
  'Asia/Nicosia': '(UTC+02:00, +03:00) Asia/Nicosia',
  'Asia/Oral': '(UTC+04:00, +05:00) Asia/Oral',
  'Asia/Pontianak': '(UTC+07:00) Asia/Pontianak',
  'Asia/Qatar': '(UTC+03:00) Asia/Qatar',
  'Asia/Qostanay': '(UTC+05:00, +06:00) Asia/Qostanay',
  'Asia/Qyzylorda': '(UTC+05:00, +06:00) Asia/Qyzylorda',
  'Asia/Riyadh': '(UTC+03:00) Asia/Riyadh',
  'Asia/Samarkand': '(UTC+05:00) Asia/Samarkand',
  'Asia/Seoul': '(UTC+09:00) Asia/Seoul',
  'Asia/Shanghai': '(UTC+08:00) Asia/Shanghai',
  'Asia/Taipei': '(UTC+08:00) Asia/Taipei',
  'Asia/Tashkent': '(UTC+05:00) Asia/Tashkent',
  'Asia/Tbilisi': '(UTC+03:00, +04:00, +05:00) Asia/Tbilisi',
  'Asia/Thimphu': '(UTC+06:00) Asia/Thimphu',
  'Asia/Tokyo': '(UTC+09:00) Asia/Tokyo',
  'Asia/Ulaanbaatar': '(UTC+08:00, +09:00) Asia/Ulaanbaatar',
  'Asia/Urumqi': '(UTC+06:00) Asia/Urumqi',
  'Asia/Yangon': '(UTC+06:30) Asia/Yangon',
  'Asia/Yerevan': '(UTC+04:00, +05:00) Asia/Yerevan',
  'Atlantic/Azores': '(UTC-01:00, +00:00) Atlantic/Azores',
  'Atlantic/Bermuda': '(UTC-04:00, -03:00) Atlantic/Bermuda',
  'Atlantic/Canary': '(UTC+00:00, +01:00) Atlantic/Canary',
  'Atlantic/Cape_Verde': '(UTC-01:00) Atlantic/Cape Verde',
  'Atlantic/Faroe': '(UTC+00:00, +01:00) Atlantic/Faroe',
  'Atlantic/Madeira': '(UTC+00:00, +01:00) Atlantic/Madeira',
  'Atlantic/South_Georgia': '(UTC-02:00) Atlantic/South Georgia',
  'Atlantic/Stanley': '(UTC-04:00, -03:00) Atlantic/Stanley',
  'Australia/Adelaide': '(UTC+09:30, +10:30) Australia/Adelaide',
  'Australia/Brisbane': '(UTC+10:00) Australia/Brisbane',
  'Australia/Broken_Hill': '(UTC+09:30, +10:30) Australia/Broken Hill',
  'Australia/Darwin': '(UTC+09:30) Australia/Darwin',
  'Australia/Eucla': '(UTC+08:45, +09:45) Australia/Eucla',
  'Australia/Hobart': '(UTC+10:00, +11:00) Australia/Hobart',
  'Australia/Lindeman': '(UTC+10:00) Australia/Lindeman',
  'Australia/Lord_Howe': '(UTC+10:30, +11:00) Australia/Lord Howe',
  'Australia/Melbourne': '(UTC+10:00, +11:00) Australia/Melbourne',
  'Australia/Perth': '(UTC+08:00, +09:00) Australia/Perth',
  'Australia/Sydney': '(UTC+10:00, +11:00) Australia/Sydney',
  'Europe/Andorra': '(UTC+01:00, +02:00) Europe/Andorra',
  'Europe/Athens': '(UTC+02:00, +03:00) Europe/Athens',
  'Europe/Belgrade': '(UTC+01:00, +02:00) Europe/Belgrade',
  'Europe/Berlin': '(UTC+01:00, +02:00) Europe/Berlin',
  'Europe/Brussels': '(UTC+01:00, +02:00) Europe/Brussels',
  'Europe/Bratislava': '(UTC+01:00, +02:00) Europe/Bratislava',
  'Europe/Bucharest': '(UTC+02:00, +03:00) Europe/Bucharest',
  'Europe/Budapest': '(UTC+01:00, +02:00) Europe/Budapest',
  'Europe/Chisinau': '(UTC+02:00, +03:00) Europe/Chisinau',
  'Europe/Dublin': '(UTC+00:00, +01:00) Europe/Dublin',
  'Europe/Gibraltar': '(UTC+01:00, +02:00) Europe/Gibraltar',
  'Europe/Helsinki': '(UTC+02:00, +03:00) Europe/Helsinki',
  'Europe/Istanbul': '(UTC+02:00, +03:00) Europe/Istanbul',
  'Europe/Kyiv': '(UTC+02:00, +03:00) Europe/Kyiv',
  'Europe/Lisbon': '(UTC+00:00, +01:00) Europe/Lisbon',
  'Europe/London': '(UTC+00:00, +01:00) Europe/London',
  'Europe/Madrid': '(UTC+01:00, +02:00) Europe/Madrid',
  'Europe/Malta': '(UTC+01:00, +02:00) Europe/Malta',
  'Europe/Paris': '(UTC+01:00, +02:00) Europe/Paris',
  'Europe/Prague': '(UTC+01:00, +02:00) Europe/Prague',
  'Europe/Riga': '(UTC+02:00, +03:00) Europe/Riga',
  'Europe/Rome': '(UTC+01:00, +02:00) Europe/Rome',
  'Europe/Simferopol': '(UTC+02:00, +03:00, +04:00) Europe/Simferopol',
  'Europe/Sofia': '(UTC+02:00, +03:00) Europe/Sofia',
  'Europe/Tallinn': '(UTC+02:00, +03:00) Europe/Tallinn',
  'Europe/Tirane': '(UTC+01:00, +02:00) Europe/Tirane',
  'Europe/Vienna': '(UTC+01:00, +02:00) Europe/Vienna',
  'Europe/Vilnius': '(UTC+02:00, +03:00) Europe/Vilnius',
  'Europe/Warsaw': '(UTC+01:00, +02:00) Europe/Warsaw',
  'Europe/Zurich': '(UTC+01:00, +02:00) Europe/Zurich',
  'Indian/Chagos': '(UTC+06:00) Indian/Chagos',
  'Indian/Maldives': '(UTC+05:00) Indian/Maldives',
  'Indian/Mauritius': '(UTC+04:00, +05:00) Indian/Mauritius',
  'Pacific/Apia': '(UTC-11:00, -10:00, +13:00, +14:00) Pacific/Apia',
  'Pacific/Auckland': '(UTC+12:00, +13:00) Pacific/Auckland',
  'Pacific/Bougainville': '(UTC+10:00, +11:00) Pacific/Bougainville',
  'Pacific/Chatham': '(UTC+12:45, +13:45) Pacific/Chatham',
  'Pacific/Easter': '(UTC-06:00, -05:00) Pacific/Easter',
  'Pacific/Efate': '(UTC+11:00) Pacific/Efate',
  'Pacific/Fakaofo': '(UTC-11:00, +13:00) Pacific/Fakaofo',
  'Pacific/Fiji': '(UTC+12:00, +13:00) Pacific/Fiji',
  'Pacific/Galapagos': '(UTC-06:00) Pacific/Galapagos',
  'Pacific/Gambier': '(UTC-09:00) Pacific/Gambier',
  'Pacific/Guadalcanal': '(UTC+11:00) Pacific/Guadalcanal',
  'Pacific/Guam': '(UTC+10:00) Pacific/Guam',
  'Pacific/Honolulu': '(UTC-10:00) Pacific/Honolulu',
  'Pacific/Kanton': '(UTC+13:00) Pacific/Kanton',
  'Pacific/Kiritimati': '(UTC+14:00) Pacific/Kiritimati',
  'Pacific/Kosrae': '(UTC+11:00) Pacific/Kosrae',
  'Pacific/Kwajalein': '(UTC+12:00) Pacific/Kwajalein',
  'Pacific/Marquesas': '(UTC-09:30) Pacific/Marquesas',
  'Pacific/Nauru': '(UTC+12:00) Pacific/Nauru',
  'Pacific/Niue': '(UTC-11:00) Pacific/Niue',
  'Pacific/Norfolk': '(UTC+11:00, +11:30, +12) Pacific/Norfolk',
  'Pacific/Noumea': '(UTC+11:00) Pacific/Noumea',
  'Pacific/Pago_Pago': '(UTC-11:00)Pacific/Pago Pago',
  'Pacific/Palau': '(UTC+09:00)Pacific/Palau',
  'Pacific/Pitcairn': '(UTC-08:00)Pacific/Pitcairn',
  'Pacific/Port_Moresby': '(UTC+10:00)Pacific/Port Moresby',
  'Pacific/Rarotonga': '(UTC-10:00)Pacific/Rarotonga',
  'Pacific/Tahiti': '(UTC-10:00)Pacific/Tahiti',
  'Pacific/Tarawa': '(UTC+12:00)Pacific/Tarawa',
  'Pacific/Tongatapu': '(UTC+13:00, +14:00)Pacific/Tarawa',
};
export const DEFAULT_TIMEZONE = 'Europe/Bucharest';
export const DEFAULT_LOCALE = 'en-US';

export const CARRIER_COUNTRY_CODE_STOARGE_KEY =
  'CARRIER_COUNTRY_CODE_STOARGE_KEY';

export const COUNTRIES_CENTER_POINT_COORDINATES: Record<
  string,
  LatLngExpression
> = {
  ro: [45.9432, 24.9668],
  sk: [48.669, 19.699],
};

export const RO_COUNTY_CODE = 'ro';

// [Minimum Latitude, Maximum Latitude, Minimum Longitude, Maximum Longitude]
export const COUNTRIES_COORDINATES_BOUNDS: Record<string, number[]> = {
  ro: [43.618682, 48.2654738, 20.2619955, 30.0454257],
  sk: [47.731389, 49.613333, 17.158889, 22.57],
};

export const COUNTRIES_MAPPING: Record<string, string> = {
  ro: 'Romania',
  sk: 'Slovakia',
};

export const EXTERNAL_DOMAINS = ['nominatim.openstreetmap.org'];
export const UNAUTH_CARRIER_LOCALE_STOARGE_KEY = 'UNAUTH_CARRIER_LOCALE';

export const drawerSizeToClassNames: Record<number, string> = {
  1: 'small-drawer-connected',
  2: 'medium-drawer-connected',
  3: 'large-drawer-connected',
  4: 'small-half-drawer-autonomous',
  5: 'small-full-drawer-autonomous',
  6: 'medium-half-drawer-autonomous',
  7: 'medium-full-drawer-autonomous',
  8: 'large-half-drawer-autonomous',
  9: 'large-full-drawer-autonomous',
  10: 'extra-large-full-drawer-autonomous',
};
