import { useQuery } from 'react-query';
import axios from './interceptors';
import {
  Drawer,
  DrawerDBReady,
  DrawerToSave,
  Locker,
  DrawersToUpdate,
} from '../types/locker.types';
import {
  getCountryName,
  getLocale,
  getQueryString,
  getSessionStorageItem,
  getStartOfTheDayEndOfTheDayUTC,
  getTimezone,
} from '../utils';
import {
  ChangePasswordParams,
  ForgotPasswordParams,
  KeyValueParams,
  PostLoginParams,
  QueryParams,
} from '../types/general.types';
import { PaymentCredentials } from '../types/payment.types';
import { CARRIER_COUNTRY_CODE_STOARGE_KEY } from '../constants';

// lockers endpoints
export const useLockers = (queryParams: QueryParams) => {
  return useQuery(['lockers', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/lockers?${queryString}`);
    return data;
  });
};

export const createDrawers = (params: {
  drawers: DrawerToSave[] | Drawer[] | DrawerDBReady[];
  lockerCode: string;
}) => {
  const { lockerCode, drawers } = params;
  return axios.post(`/lockers/${lockerCode}/drawers`, drawers);
};

export const useDrawerTypes = () => {
  return useQuery(['drawerTypes'], async () => {
    const { data } = await axios.get('/drawerTypes');
    return data;
  });
};

export const useDrawers = (lockerCode: string, enabled = true) => {
  return useQuery(
    ['drawers', lockerCode],
    async () => {
      const { data } = await axios.get(`/lockers/${lockerCode}/drawers`);
      return data;
    },
    { enabled }
  );
};

// endpoints packages

export const usePackages = (queryParams: QueryParams) => {
  return useQuery(
    ['packages', queryParams],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(`/packages?${queryString}`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const useGetPackageByAWB = (AWB: string) => {
  return useQuery(
    ['package', AWB],
    async () => {
      const { data } = await axios.get(`/packages/${encodeURIComponent(AWB)}`);
      return data;
    },
    {
      enabled: false,
    }
  );
};

export const useGetPackageSnapshots = (packageId: string) => {
  return useQuery(['packageSnapshots', packageId], async () => {
    const { data } = await axios.get(`/packages/${packageId}/packageSnapshots`);
    return data;
  });
};

export const useGetPackageBySnapshoyId = (id: number) => {
  return useQuery(['packageSnapshot', id], async () => {
    const { data } = await axios.get(`/packageSnapshots/${id}`);
    return data;
  });
};

export const useLocker = (lockerCode: string) => {
  return useQuery(['locker', lockerCode], async () => {
    const { data } = await axios.get(`/lockers/${lockerCode}`);
    return data;
  });
};

export const useUpdateLockerDrawers = (params: {
  lockerCode: string;
  drawersToUpdate: DrawersToUpdate[];
}) => {
  const { lockerCode, drawersToUpdate } = params;
  return axios.put(`/lockers/${lockerCode}/drawers`, drawersToUpdate);
};

export const useDrawerIncidents = (queryParams: QueryParams) => {
  return useQuery(['drawer/incidents', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/incidents/drawers?${queryString}`);
    return data;
  });
};

export const usePackageIncidents = (queryParams: QueryParams) => {
  return useQuery(['package/incidents', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/incidents/packages?${queryString}`);
    return data;
  });
};

export const updateLockerById = (data: Locker) => {
  return axios.put(`/lockers/${data.lockerCode}`, data);
};

export const useLockerPackages = (
  queryParams: QueryParams,
  lockerCode: string
) => {
  return useQuery(
    ['lockers/packages', queryParams, lockerCode],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(
        `/lockers/${lockerCode}/packages?${queryString}`
      );
      return data;
    }
  );
};

export const getDrawersByLockerId = (lockerId: string) => {
  return useQuery('drawers', async () => {
    const { data } = await axios.get(`lockers/${lockerId}`);
    return data;
  });
};

export const useCounties = () => {
  const countryCode = getSessionStorageItem(CARRIER_COUNTRY_CODE_STOARGE_KEY);
  return useQuery(
    'counties',
    async () => {
      const { data } = await axios.get(`/counties/${countryCode}`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const useLocalitiesByCountyId = (countyId: string, enabled = true) => {
  return useQuery(
    `locality-${countyId}`,
    async () => {
      const { data } = await axios.get(`/counties/${countyId}/localities`);
      return data;
    },
    {
      enabled,
    }
  );
};

export const useAppVersions = (queryParams: QueryParams) => {
  return useQuery(['app-versions', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/app-versions?${queryString}`);
    return data;
  });
};

export const useApiKey = () => {
  return useQuery(
    'api-key',
    async () => {
      const { data } = await axios.get('/customers/getSecret');
      return data;
    },
    { enabled: false, retry: false, cacheTime: 0 }
  );
};

export const useBtCredentials = () => {
  return useQuery('bt-credentials', async () => {
    const { data } = await axios.get(`/customers/bt-credentials`);
    return data;
  });
};

export const createBtCredentials = (credentials: PaymentCredentials) => {
  return axios.post('/customers/bt-credentials', credentials);
};

export const downloadReport = (params: {
  queryParams: QueryParams;
  reportType: number;
}) => {
  const { queryParams, reportType } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(`/reports/download-fleed-load/${reportType}?${queryString}`);
};

export const getDrawersLoad = async (params: {
  queryParams: QueryParams;
  lockerCode: string;
}) => {
  const { queryParams, lockerCode } = params;
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(
    `/reports/fleet-load/${lockerCode}/drawers?${queryString}`
  );
  return data;
};

export const getFleetLoadReport = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-load?${queryString}`);
  return data;
};

export const getReportInfo = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-global?${queryString}`);
  return data;
};

export const getFleetUptimeReport = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-uptime?${queryString}`);
  return data;
};

export const downloadDrawersLoadReport = (params: {
  queryParams: QueryParams;
  lockerCode: string;
}) => {
  const { queryParams, lockerCode } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(
    `/reports/download-drawer-load/${lockerCode}?${queryString}`
  );
};

export const getAllLockers = () => {
  return axios.get('lockers/lite');
};

export const useMapSearchApi = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);

  const { data } = await axios.get(
    `https://nominatim.openstreetmap.org/search?format=jsonv2&country=${getCountryName()}&${queryString}`
  );
  return data;
};

export const getLocalitiesByCountyId = (countyId: string) => {
  return axios.get(`/counties/${countyId}/localities`);
};

export const getCustomerBillingReport = async (params: {
  queryParams: QueryParams;
}) => {
  const { queryParams } = params;
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/reports/billing-data?${queryString}`);
  return data;
};

export const downloadBillingData = (params: { queryParams: QueryParams }) => {
  const { queryParams } = params;
  const queryString = getQueryString(queryParams);
  return axios.get(`/reports/download-billing-data?${queryString}`);
};

export const login = (params: PostLoginParams) => {
  return axios.post('account/login', params);
};

export const forgotPassword = (params: ForgotPasswordParams) => {
  return axios.post('account/forgot-password', params);
};

export const changePassword = (params: ChangePasswordParams) => {
  return axios.post('account/change-password', params);
};

export const getClusters = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/lockers/clusters?${queryString}`);
  return data;
};

export const getClusterLockers = async (clusterId: string) => {
  const { data } = await axios.get(`/lockers/${clusterId}/lockers`);
  return data;
};

export const updateAccountSettings = (params: KeyValueParams) => {
  return axios.put('account/settings', {
    locale: getLocale(),
    timezone: getTimezone(),
    ...params,
  });
};
