import React, { BaseSyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../common/LoadingComponent';
import ErrorDialog from '../common/ErrorDialog';
import { login } from '../../services/requests';
import { SuccessLoginData } from '../../types/general.types';
import PasswordField from '../common/PasswordField';

interface Props {
  setLoginData: (data: SuccessLoginData) => void;
}

export default function Login({ setLoginData }: Props) {
  const [formState, setFormState] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    usernameError: false,
    passwordError: false,
  });

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const loginMutation = useMutation(login, {
    onSuccess(data) {
      if (data?.data?.accessToken && data?.data?.userSettings) {
        setLoginData(data.data);
      }
    },
  });

  const handleChange = (e: BaseSyntheticEvent) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });

    setErrors({
      ...errors,
      [`${e.target.name}Error`]: false,
    });
  };

  const handleSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    // on the back-end if no username is set
    // the email is used as the username
    // since the user has never set uo an username
    // the filed lable is Email and we check for a valid email
    const isEmailValid = isEmail(formState.username);
    const isPasswordEmpty = isEmpty(formState.password);

    if (!isEmailValid) {
      setErrors((prevState) => {
        return {
          ...prevState,
          usernameError: true,
        };
      });
    }
    if (isPasswordEmpty) {
      setErrors((prevState) => {
        return {
          ...prevState,
          passwordError: true,
        };
      });
    }

    if (isEmailValid && !isPasswordEmpty) {
      loginMutation.mutate(formState);
    }
  };

  React.useEffect(() => {
    setErrors({ usernameError: false, passwordError: false });
  }, [i18n.language]);

  return (
    <>
      {loginMutation.isLoading && <LoadingComponent />}
      {loginMutation.isError && (
        <ErrorDialog status message={t('something_went_wrong')} />
      )}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 90px)',
          '@media (max-height:650px)': {
            height: 'auto',
          },
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            borderRadius: 6,
            p: 3,
            m: 3,
            width: '508px',
          }}
          elevation={2}
        >
          <form
            aria-label='create-account'
            onSubmit={handleSubmit}
            noValidate
            style={{ width: '100%' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  mb: 2,
                  fontWeight: 'bold',
                  color: 'primary.main',
                }}
              >
                {t('login_to_your_account')}
              </Typography>
              <Typography
                sx={{ width: '100%', color: '#666666', marginBottom: '0.5rem' }}
              >
                {t('email')}
              </Typography>
              <TextField
                sx={{
                  mb: 1.5,
                  width: '100%',
                }}
                value={formState.username}
                name='username'
                onChange={handleChange}
                required
                error={errors.usernameError}
                helperText={errors.usernameError && t('invalid_email_address')}
              />
              <PasswordField
                value={formState.password}
                name='password'
                label={t('password')}
                onChange={handleChange}
                hasError={errors.passwordError}
                errMsg={t('empty_password_err')}
              />

              <Stack
                direction='column'
                spacing={2}
                justifyContent='center'
                alignItems='center'
              >
                <Button
                  sx={{
                    textTransform: 'none',
                    fontSize: 12,
                    fontWeight: 'regular',
                  }}
                  onClick={() => {
                    navigate('/forgot-password');
                  }}
                >
                  {t('forgot_my_password')}
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    mb: 3,
                  }}
                  type='submit'
                >
                  {t('login')}
                </Button>
              </Stack>
            </Box>
          </form>
        </Paper>
      </Box>
    </>
  );
}
