import {
  CARRIER_ACCESS_TOKEN_KEY,
  CARRIER_COUNTRY_CODE_STOARGE_KEY,
  CARRIER_LOCALE_STOARGE_KEY,
  CARRIER_TIMEZONE_STOARGE_KEY,
} from '../constants';
import { getSessionStorageItem, removeSessionStorageKey } from '../utils';

export const getUser = () => {
  const authUser = getSessionStorageItem(CARRIER_ACCESS_TOKEN_KEY);
  return authUser ? JSON.parse(authUser) : null;
};

export const logout = () => {
  removeSessionStorageKey(CARRIER_ACCESS_TOKEN_KEY);
  removeSessionStorageKey(CARRIER_LOCALE_STOARGE_KEY);
  removeSessionStorageKey(CARRIER_TIMEZONE_STOARGE_KEY);
  removeSessionStorageKey(CARRIER_COUNTRY_CODE_STOARGE_KEY);
  window.location.href = '/';
};
